
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        btn_type: {
            type: String,
            default: 'primary'
        },
        btn_text: {
            type: String,
            default: 'button'
        },
        btn_disabled: {
            type: Boolean,
            default: false
        },
        btn_href: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const redirectToLink = () => {
            if(props.btn_href.length) {
                window.location.href = props.btn_href
            }
        }

        return {
            redirectToLink
        }
    }
})
