
import { defineComponent, reactive } from 'vue';

export default defineComponent({
    props: {
        list_title: {
            type: String,
            default: 'title'
        },
        list_text: {
            type: String,
            default: 'text'
        },
        list_link: {
            type: String,
            default: ''
        }
    },
    setup() {
        const visible_modal = reactive({
            state: false
        })

        const openModal = (state: boolean) => {
            visible_modal.state = state
        }

        const redirectToLink = (href: string) => {
            window.location.href = href
        }

        return {
            visible_modal,
            openModal,
            redirectToLink
        }
    }
})
