
import { defineComponent, reactive } from 'vue';

export default defineComponent({
    props: {
        menubar: {
            type: Array,
            default: () => []
        }
    },
    setup(_, { emit }) {
        const active_menubar = reactive({
            id: 1
        })

        const selectMenu = (id: number) => {
            active_menubar.id = id
            emit('set-menubar', id)
        }

        return {
            active_menubar,
            selectMenu
        }
    }
})
