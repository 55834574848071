
import { defineComponent, ref } from 'vue';
import { tryOnMounted, tryOnUnmounted } from '@vueuse/core';

export default defineComponent({
    name: 'app',
    setup() {
        const scrollY = ref(0);

        // Функция обновления позиции логотипа при скролле
        const updateScrollPosition = () => {
            scrollY.value = -window.scrollY / 2;
        };

        // Добавление слушателя события скролла при монтировании компонента
        tryOnMounted(() => {
            window.addEventListener('scroll', updateScrollPosition);
        });

        // Удаление слушателя события скролла при размонтировании компонента
        tryOnUnmounted(() => {
            window.removeEventListener('scroll', updateScrollPosition);
        });

        return {
        scrollY
        };
    },
});
