
import axios from 'axios';
import { defineComponent, onMounted, reactive, watch } from 'vue';
import onScrollAnimate from '../../unilities/scrollAnimate'


export default defineComponent({
    setup() {
        const form = reactive({
            field: [
                {
                    id: 'surname',
                    type: 'text',
                    value: '',
                    required: true,
                    valid: false,
                    pattern: /^[a-zA-Zа-яА-Я\s]+$/,
                    label: 'Фамилия *',
                    error: ''
                },
                {
                    id: 'first_name',
                    type: 'text',
                    value: '',
                    required: true,
                    valid: false,
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    label: 'Имя *',
                    error: ''
                },
                {
                    id: 'patronymic',
                    type: 'text',
                    value: '',
                    required: true,
                    label: 'Отчество *',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: ''
                },
                {
                    id: 'birthday',
                    type: 'text',
                    value: '',
                    required: true,
                    label: 'Дата рождения *',
                    mask: 'XX.XX.XXXX',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: ''
                },
                {
                    id: 'country',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Страна, где вы сейчас учитесь *',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: '',
                    selector: true,
                    combo_box: [
                        {   
                            mask: '+375 XX XXX-XX-XX',
                            name: 'Республика Белоруссия',
                            id: 1,
                        },
                        {
                            mask: '+7-XXXX-XXXXX',
                            name: 'Республика Казахстан',
                            id: 2,
                        },
                        {
                            mask: '+996 XXX XXX-XXX',
                            name: 'Киргизская Республика',
                            id: 3,
                        },
                        {
                            mask: '+373-XXXX-XXXX',
                            name: 'Республика Молдова',
                            id: 4,
                        },
                        {
                            mask: '+7 XXX XXX XX-XX',
                            name: 'Российская Федерация',
                            id: 5,
                        },
                        {
                            mask: '+992-XX-XXX-XXXX',
                            name: 'Республика Таджикистан',
                            id: 6,
                        },
                        {
                            mask: '+998-71-XXXXXXX',
                            name: 'Республика Узбекистан',
                            id: 7,
                        },
                    ]
                },
                {
                    id: 'town',
                    type: 'text',
                    value: '',
                    required: true,
                    label: 'Город, где вы сейчас учитесь *',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                },
                {
                    id: 'phone',
                    type: 'text',
                    value: '',
                    required: true,
                    label: 'Телефон *',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                },
                {
                    id: 'email',
                    type: 'text',
                    value: '',
                    required: true,
                    label: 'Email *',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                },
                {
                    id: 'study_place',
                    type: 'text',
                    value: '',
                    required: true,
                    label: 'Учебное заведение *',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                },
                {
                    id: 'level_of_education',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Уровень образования *',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: '',
                    selector: true,
                    combo_box: [
                        {
                            name: () => {
                                return 'Бакалавриат'
                            } ,
                            id: 1,
                        },
                        {
                            name:() => {
                                return 'Специалитет'
                            } ,
                            id: 2,
                        }
                    ]
                },
                {
                    id: 'course_of_study',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Курс обучения *',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: '',
                    selector: true,
                    combo_box: [
                        {
                            name: () => {
                                return '3 курс'
                            },
                            id: 1,
                        },
                        {
                            name: () => {
                                return '4 курс'
                            },
                            id: 2,
                        },
                        {
                            name: (): string | null => {
                                return form.field.find(form_field => form_field.id === 'level_of_education')?.value === 'Специалитет' ? '5 курс' : null;
                            },
                            id: 3,
                        }
                    ]
                },
                {
                    id: 'division',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Дивизион *',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: '',
                    selector: true,
                    combo_box: [
                        {
                            name: () => {
                                return 'Дивизион "Восток" (Уральский, Сибирский и Дальневосточный федеральные округа)'
                            } ,
                            id: 1,
                        },
                        {
                            name:() => {
                                return 'Дивизион "Запад" (Центральный и Северо-Западный федеральные округа)'
                            },
                            id: 2,
                        },
                        {
                            name:() => {
                                return 'Дивизион "Юг" (Приволжский, Южный, Северо-Кавказский федеральные округа)'
                            },
                            id: 3,
                        },
                        {
                            name:() => {
                                return 'Дивизион "Зарубежный" (страны СНГ, кроме Российской Федерации)'
                            },
                            id: 4,
                        },
                    ]
                },
            ]
        })

        const countries_flag = [
            {
                mask: '+7 XXX XXX XX-XX',
                name: 'Российская Федерация',
                icon: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgB7dixDcIwEEbh/4iRUkWMxgZkFWagoWMAGugiKgZgA2CHSFFKODwCTaSz9b7O5ZN18skSAADLMXffTNO0VdnGrusuNs/zKR96FS5fSr9SRYiJipioiImKmKjScHsqrRuVrm2T0uH4kMlVOndjZsIiJipioiImqrrWmd5e+Y9GxbO8AaRd81YdvsxMWMRERUxUxERVT4zbmPRp9vn1vKtwNpyvAgAA//kB/DYiqxG2mJsAAAAASUVORK5CYII='
            },
            {
                mask: '+375 XX YYY-YY-YY',
                name: 'Республика Белоруссия',
                icon: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJgSURBVHgB7Zm9axRRFMXPvBnRbIIsuvGjialk1UIQ7BZMmgU/Chv/Lytb/wsLmxjYQlCDjZLOZNHCGGRhzVgkM89z7htDSJlUF94Pkp1Jmlzuxzn3BshkMplM5uwU8/l8Lca4evoXl95/woWXr+CEz1UIYUNPxUGNuNhD8WsfcXmAtn+ZP4QXZpW+l+8mKGoGcmvFng+fjeGMvgUSmAVlou31LAthbx/QsyPC/4fm4QOUmxM0ayOEnSm8cRyIesQ+mY3oLBvCAlFZlR+20DweW4+o6b1hPdLeHVow4FezPgIi3GEZCR+30HLkll+2LQhNMG9YIO2dYdKR64PUK157JOxOUWliMaDyzVuPldU1+5/aRm7JElNG3JZW+LqNo04/2tUVe/eGTa3m3jD5KyQ9aTjFvGEZicyCGUaKoRReQXnDMlKwpIqYbErgCA70W82jETxhgUAZKDrzyEZXv3gjea3OZ8WiE/UDp1MLSxTAnzSLg4HtUuWuP/ebvBYDCEyFRPHwxfPU+M5U0TIi56s/XhpSbUys4b2RBFFNztFrAfyt7d0byTRyV1dZtRTG8G1q795IgkjXq7LSXqISi9ecCqIsScMsyGsdPRnbklXfvoHvN/fghXQOYrPblshJZWsuA/lxZYbXTzfhBSstZUQ2pV1KC5W8lzfS+KUA2qTSusvjg0dSs3O1NcPIPUTHB9d3rZN3X48cB1KxpGTdSyp767VHdArSlmhCWMDtgW7Ge2/frvHsFTONKq/ffjREVPwnzzo/78eFBaYi0spftc/Fi8tuLo68Newgk8lkMplz8A82DPeE0fFsCAAAAABJRU5ErkJggg=='
            },
            {
                mask: '+7-XXXX-XXXXX',
                name: 'Республика Казахстан',
                icon: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAROSURBVHgB7Vc7TxRhFL2zu7ADKrs+wmoh2Ag2AgVoIUIhkU4S7Cws/C/+AFvtKEzUwphoQyiUQLFAIgIFjwawYcGEHVB2Bpgd77nDED6eu7NDdJI5yWZ3Zmfm+8695557hyhChAgRIkSIUBK0l6Pf22w7/pHCDsd5lWAibaTRLQo7Ylpr7PC51gvT1JhcpjBCIXP/0jjpMVN+N9csUNiQOHiQjFmUThgUViiZqeGs6JpJmapVKhR1ChsUMg652THslBALGxSZzW01kVGdYlIa5XdTFDSSmkWaRmQWk3QeUMggIxlaFROYL7RTEIAziqmAhK1LTeZ5nSWzgYKGIrMGXvhO7TxZHLlK7Rmbhs1DtivbGcn6ktVAi0wCddmVGgncbJTMIIKQl8nFj034hc73ZqpyQkKPm9R37ct+cHBubLOdM39bzpnFpsBkp2RGI5dQiiOmkX+kE3mpO0T+ef1bJcvXq3P05CrI/RTDAemgoJBBRNPxDZaBxZnx72b1bO257XrqvTx04jWQGaSXClBqiswWWdNwHHxDBn7gTRDIwGkbxXWtF6c5f45kJ7eToUqhZAZEcjv18rscA8Dog3tduAItpea8GqUDoq7EFBQyNziajfoy3WIipT4U16FGeq8M7d3jyCZh82cBZoB7PAPAbNhVNyJZ9YNDNWPuy6RULWMjyEw6blATf8MJEWf0kdMaL/7DNTAKEMe69y5NkOnoUnMVk8FG8DHsOuk1pQIbQzSba+bleK7QJDL98Kv/WEIIwPu1flGB1zwlIBxAPGPZ8tdQFQNY54ULvJBVxpAJ8qizO+QSAQnIRyy3SDSQe0bd7FwZJgu3hINluc+kq9zMezWK/y1ZO8kvjeQLChlEpKV2mizS6cefu1QqvAEVGWmuXXA7Pv9u4QkgU70qzzI2OuVayAlTBjD5u0W+USvZjQ7+nqAVdrVS6u04KDLD4teTbgTLcbOxzQ4aWHUzMGw8oL6rn8XdppjE3JZr8cgUaqGO+xhGmizfYzlJuQfHIAhJZjf8z4RHBs1GzSWBGlgqQ7tuwddxMxylYc4C3G3Y6JQNHhdp1EuzvuBKjmsFpL4aDyvqN+psxgOgsZs64P/l4c3KC3qcHmKpztDgeg+72zxn260NPA/vSCnOjMkZwTFIo1a62Y7frT0tK3hnk2F54bNoucOmHwzme3jDBtvs+D4JyAkEMWBmrQ45j36CGQ0SAxFcUymOjjN7faaS9w3IChIDsGHYLZ4n0mKDkFGG6xNALaGuLDtgMrBFLAoHKrdmTsK3fCdL7pFMzyACoM/MyvvNTTGPoKCQabkwI518lodMFOWS/1eafXjF/5rryZvfgpDUcVCs2WA3Qs/AbFbJy9lJAInzIgIcMQBjz0o18tmG/yEUMhhlCrQ3UlD4oMgMxQhLhp1OlTHO/C9IHD4RRhIeEnbcnowVY5Oao6UpxLAd+xNFiBAhQoQIEUrDX1qJ/kXKD6QfAAAAAElFTkSuQmCC'
            },
            {
                mask: '+992-XX-XXX-XXXX',
                name: 'Республика Таджикистан',
                icon: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHnSURBVHgB7dm/SwJhGAfw751vXtFPihyKMqgocAlraWtvb4z+i6ZoKeg/qK3mlmoI2nJpi2ooWlzKalAsUshfp2/vo5xewektB3fyfBZfODzv+7xfT1GAMcYYY65o+2dyqQrcI/hORA0YcTpqCInlaAWxySq+Cxqe3gWePkS7EyI2YSKZDqFkapgaraJU0ZDO64gM1jDUV1PHBLwggZm2Z95cLaoLkEh96rh4MOoX2EnJtK1VkLAaCKHB5Ao6vOQYhiZMQWhH3AYh9snTjlhSXyF4zXFUscnGiDP5UMcg8WmzPvn68yZaa6oZ1YvQ41zEhJccw1BFSNHl61uB/9fMzuuaOZ7dqsX8eBXDfRLt3L22qkU1s4JRzayq2ddecTz73Yuov/GNHomNleKfQFSjeNRsrldnK81qxaOtNdXKqhlVjiropbajOr3txdVjWN0EdKwtlDE33rgYmnzuR2uu31RoazdyhVa17LWiymU83hnt+PB5Ld8/eo2AKwsjIbYud9Qnjrvbrr9JiOLuNjq0LRA0CmMuLkLXgx9GStkFW2LDYfyKw/gVh/GrrgojBvYOYI5FEHhGGCKZvYGWlQg69QUA4mg9gW7BNwC/4jB+xWH8isP4lVD/zTyo3y4TCL5zMMYYY8ydXyN8scZeXCWdAAAAAElFTkSuQmCC'
            },
            {
                mask: '+998-71-ХХХХХХХ',
                name: 'Республика Узбекистан',
                icon: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGOSURBVHgB7dkxTwIxGAbgt9fjFAzRhHMkYWPVf2GcHfwr/hpXNwcXB1dx1JVJAomJBAzEeKCkrf1KguYmhjbpke8ZuKMd6MvX9pocwBhjjLGtCPow5xe3EOIIlSZehDm77ECqV1SdwSDBDuEwseIwseIwseIwsRKLxaJj3sfVPwEoNUjpao5zhCBWE5hajvTzGVo2oBtdCF3AJA2EEGyaCVWgNrlz9+qgC7Pfdm3Z2zVCcdNMa+11miVF31Vh8yOlatB3rKYwe214FOagSdPKDRjrqZbMn5CNbzb9wgaRyyF8C1KZMgqnstx3Jcr8VyadP0Lawf9n7OJHadGnHw8Q3yP4lMIz1Tzd3NPagQ0iliNIO3CqDO1qdFXNE9fnk/cwtNBp8GnRWzfIOkzWgk5zJHb9SNuHr74L7XuL9h6G0DasbAA567nFTqQaIrFBlN3lNG3VAZ41QcI4P9P1v19r/bUdwlUtlPTqvg6J6jPgU3O8OEysOEysOEysdi7MzD49B6g4+6JpBsYYY4xt5xf49Y29Xir5SgAAAABJRU5ErkJggg=='
            },
            {
                mask: '+373-XXXX-XXXX',
                name: 'Республика Молдова',
                icon: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ4SURBVHgB7ZfPaxNBFMe/u82vTdZWpdrgKSmFIlirHgRPzUG0p+LZg4g3j4I/Tv4HQvDitfYgeFVPVvEk6EXxVtEUIpF2FWxWu61bs9n1zc6WiqeQvAlLmU/4kuxmsrMv773vzAIajUaj0Wh6wqjNL1bMsFMPDeMg+qR+8z1OTbvoG490K8BAhOH9DLqdWmQalwz0j0EvRANcwY8wMCaaJvYROpi0ooNJKzqYngkSDYkMVNIlibXUSmYqQinqy8whjWIoGeIP5gdplbRJGiFVk/O7NbBD+pSMY4Y/GD95/wZZZruIwLZJ68nn32CHv2fE35MjNWm/1aD3cdIYZJl9JYlt2LQhxyiYmpcSSey/myHwhbRM6dmmCB5RNC4df+7KHhoDO2oNYNaQTvaWgiga8lghrGW25rZxqFOCVcjBiSzYrQ5wJQfbCmJb9l6Z8KwMyvgjf9AGq13zZWYLeLLyDm1nKz58Hh3Dwosa7i6eiG/44dMqFh7PYcmblOOFq3lghS8Y6pXrx89jI/Kw6n2PT92+ugK7KBeY5TdlXDzn7I0XziZMIA82eHvGplILXVg/s5ga97D0rIpG60D8VYlKrdGy4/NxVoRtT4AVXmumq12YnMGD9Zc4e7KN+gQF9LqCG/dOY6q4ifkzDmZnXLlgVsGOkr3ZNXsO0VoIq+zjzuWP8HIZ2H4gsyGqzoYS+K2ZbrZgZWFV8tjwvXg9sQuBnClLEkYmHGwH7PBnRiyaoqljN/6vu0VmRAsdhjQAZviDye9duXCEUvELcgsjZjr6zzgFjwNqn2cEoxga+rE5rehg0ooOJq2YGDGa2A+E5gdoNBqNRqPpjb+4j5249Qlt9QAAAABJRU5ErkJggg=='
            },
            {
                mask: '+996 XXX XXX-XXX',
                name: 'Киргизская Республика',
                icon: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALoSURBVHgB7VixbhNBEJ2zDZJTIZsuRqKx6bA7TBnoMAUFKeEbqJH4B+iADlqQEBJJR1z60gWXdhnTYcocSuxb3vOMsVFSRLo96Rztk0Zj760u83bezKwjEhAQEBAQEHApRO7JbkdOoy+y6XDpm4qcSgeUbsumI3LtklwhBDJFRSBTVORLpma+GZ1fywH5kGlZ8C28vgpfp0UrI7Yi8Q2/ZNYDbPOzE+mWlBQz0oNP+Ax+W7zDL5kG7BFeeQwSTyvICgjdgjVLSoBy2xIlNXa65lF2fshQSgx05DQ7O3ytBXs3UpkxQ1P4FyA5SEWelzU7v8UbKuIDiRkD7c9FnsEnIDOF9dMFr3/yYj3tgsgn7BviQQ+fJ9jzw0lWZCfDTNQs2I8zkZfXdP2AAcIeI9gG9kycBhzPNCsk0cbzvVQl5wHZyZwgkFqktcJCH1omIqw9KKv0KCvWSsPqhqTa+P5TNFMtW5tkI+WnZqh7yilONQvMyE7p/+dsBlPrbuNUD4C1xjVmcVIEmVFiPOWG1QPnCCU3SjXYxAgwXSQUW+aYqa51s7rTDGaUW3YyPPVDWIIg/6x1L54+iTEDbMXf5kqMIBF2tgHWDp1KNZHMyC4znv49G5a/Uu1MXRuOlNAd+xP34U9EM8C5k9gzdkB2OQ83guxkGDTrZQLfsc4lsG2bO1XbR0/pMeiuXXOO8P3VmcrLFaFmCOq+CXs700DZduvwNxH4AaT0XRb85GF5dencm2uG2AX3U/EBP2QW3cytriyfQaqHV9d4rSmv9rFOWEfvZtrtXs/1IGri5Sbg925WRfDv5zpvePKcNQNrBMs5QkmxdvattkjC05XGL5nYaoQXzdhaM+uJtcJhyqL/MNNuN7I9HuH/9wwLvG9Bsl7YIMY2RwhmwcM97CL4JzNdC3QZ9NB8bN7DTLkI+f5sXgZ9bCSm+WRkifAPjaIikCkqrhSZilyXIzmDObkhmwwnXyUgICAgICDgcvgLHkn24PoQWBUAAAAASUVORK5CYII='
            }
        ]

        const active_selector = reactive({
            type: '',
            mask: ''
        })

        const pdf = '../../../public/file/champ.pdf'

        const open_modal = reactive({
            state: false
        })

        watch(() => active_selector.mask, () => {
            form.field.find(input => input.id === 'phone')!.value = ''
        })

        const redirectToLink = (href: string) => {
            window.location.href = href
        }

        const openSelector = (selector_type: string) => {
            switch (selector_type) {
                case 'country':
                    active_selector.type = 'country'
                    break;
                case 'level_of_education': 
                    active_selector.type = 'level_of_education'
                    break;
                case 'course_of_study': 
                    active_selector.type = 'course_of_study'
                    break;
                case 'division':
                    active_selector.type = 'division'    
                    break;

                default:
                    break;
            }
        }

        const selectedCountry = (name: string, mask: string) => {
            const countryInput = form.field.find(input => input.id === 'country');
            if (countryInput) {
                countryInput.value = name;
                active_selector.type = ''
                active_selector.mask = mask
            } else {
                console.error("Поле 'country' не найдено в массиве field.");
            }
        } 
        
        const registrationUser = () => {
            let fd = new FormData

            for(let i = 0; i < form.field.length; i++) {
                fd.append(form.field[i].id, form.field[i].value)
            }

            axios
                .post('/user', fd)
                .then(resp => {
                    console.log(resp);
                    open_modal.state = true
                })
                .catch(err => {                    
                    if(err.response.data === 'Некорректный формат даты рождения') {
                        form.field.find(input => input.id === 'birthday')!.error = err.response.data
                    }
                })
        }

        const setValue = (val: { type: string; value: string; }) => {
            for (let i = 0; i < form.field.length; i++) {
                if(form.field[i].id === val.type ) {  
                    if(val.value) {
                        form.field[i].value = val.value
                    }
                }
            }
        }

        const setSelector = (type:string, value: string) => {
            form.field.find(input => input.id === type)!.value = value
            validCheck(form.field.find(input => input.id === type))
            active_selector.type = ''
        }

        const validCheck = (input: any) => {                        
            if(!input.value.length && input.required) {   
                input.error = 'Поле обязательно к заполнению'
            } else {
                input.error = ''
            }
        }

        onMounted(() => {
            onScrollAnimate();
        })

        return {
            form,
            countries_flag,
            active_selector,
            open_modal,
            pdf,
            openSelector,
            selectedCountry,
            registrationUser,
            setValue,
            setSelector,
            validCheck,
            redirectToLink
        }
    }
})
    
