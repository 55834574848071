
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const router = useRouter()
        
        const menubar = [
            {
                page_name: 'Главная',
                page_link: '/'
            },
            {
                page_name: 'Чемпионат 2024',
                page_link: '/championship',
            }
        ]

        const openPage = (link: string) => {
            router.push(link)
        }

        return {
            menubar,
            openPage
        }
    }
})
