
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        list_idx: {
            type: Number,
            default: 0
        },
        list_title: {
            type: String,
            default: 'title'
        },
        list_text: {
            type: String,
            default: 'text'
        },
    },
    setup() {

    }
})
